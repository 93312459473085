import React from 'react'
import { navigate, withPrefix } from 'gatsby'
import { HorizontalBar } from 'react-chartjs-2'

const ColumnTableChar = ({ tableData = [], title, backgroundColors = [] }) => {
  const data = tableData.reduce((acc, webData) => {
    acc.push(webData.SpeedIndex)
    return acc
  }, [])

  const numEachColor = (tableData.length / backgroundColors.length).toFixed(0)

  // Generate a BackgroundColor array for each data item
  const backgroundColor = tableData.reduce((acc, tData, index) => {
    acc.push(backgroundColors[Math.floor(index / numEachColor)])
    return acc
  }, [])

  const labels = tableData.reduce((acc, webData) => {
    acc.push(webData.name || webData.url)
    return acc
  }, [])

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Speed Index',
        data,
        backgroundColor
      }
    ]
  }

  const getSlugFromUrl = url => {
    const noSshUrl = 'http://'
    const sslUrl = 'https://'

    if (url.includes(noSshUrl)) {
      const index = url.indexOf(noSshUrl) + noSshUrl.length
      return url.slice(index, url.length)
    }

    if (url.includes(sslUrl)) {
      const index = url.indexOf(sslUrl) + sslUrl.length
      return url.slice(index, url.length)
    }

    return ''
  }

  const handleOnClickLabel = navigateEvent => {
    if (navigateEvent.length > 0) {
      // eslint-disable-next-line no-underscore-dangle
      const navigateTo = getSlugFromUrl(tableData[navigateEvent[0]._index].url)
      navigate(withPrefix(`/rankings/${navigateTo}/`))
    }
  }

  const chartOptions = {
    legend: {
      display: true,
      position: 'top',
      align: 'end',
      usePointStyle: true,
      labels: {
        boxWidth: 0
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontSize: 10,
            fontColor: '#0063cb'
          }
        }
      ]
    }
  }

  return (
    <div className="w-full">
      <h3>{title}</h3>
      <HorizontalBar
        data={chartData}
        options={chartOptions}
        getElementsAtEvent={navigateEvent => handleOnClickLabel(navigateEvent)}
      />
    </div>
  )
}

export default ColumnTableChar
